.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 15px;
}
@media (max-width: 700px) {
  .container {
    width: 100%;
  }
}
.container .blockForTitleAndIcon {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  align-items: center;
}
.container .blockForTitleAndIcon h4 {
  color: white;
  font-size: var(--fontSizeInFooterHeadlines);
  font-family: Nunito, cursive;
}
.container .blockForTitleAndIcon .icon {
  color: white;
}
.container .blockForVisits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
}
.container .blockForVisits span {
  color: white;
  font-size: var(--fontSizeInFooter);
}

