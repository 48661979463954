.container {
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;


  overflow: hidden;
  border-radius: 15px;
  position: relative;
  border: 1.4px solid #31030366;;
  background-color: #c0c0c01a;
  // background-size: 100% 100%;
  // background-image: url("./../../../../../assets/images/white.png");
  //box-shadow: 0 0 5px 1px rgba(133, 133, 133, 0.25);
  box-shadow: 0 0 2px 1px #6a6a6a1a;
  display: flex;
  align-items: flex-start;
  @media (max-width: 1000px) {
    background-image: none;
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .noImage {
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ababab54;
    font-size: 70px;
    border-radius: 10px;

    border: 1px solid #bdbdbd66;
    margin: 10px;
  }

  .imageBook {
    position: relative;
    width: 100%;

    img {
      &:first-of-type {
        z-index: 100;
        position: relative;
      }

      &:last-of-type {
        z-index: 99;
        width: 77%;
        height: 52%;
        position: absolute;
        left: 13%;
        top: 22%;
        object-fit: cover;
      }
    }
  }

  .blockForImgAndSomeInfo {
    grid-area: 1 / 1 / 2 / 2;

    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    row-gap: 5px;
    @media (max-width: 800px) {
      width: 100%;
    }

    img {
      width: 100%;
      max-height: 80%;
    }

    .blockForSpans {
      display: flex;
      flex-direction: column;

      width: 100%;
      flex-grow: 9999;

      .blockForSpan {
        display: flex;
        column-gap: 5px;
        padding-left: 10px;

        .spanCategoryAndWhenPublished {
          color: #343434;
          padding-bottom: 4px;
          width: 120px;
          font-size: 1rem;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 400;
        }

        .mainInfoSpan {
          //      color: #2f2f2f;
          color: #4b4b4b;
          font-size: 1rem;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 400;
          padding-left: 2px;
        }
      }
    }
  }
}


.btnReadMore {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.containerForDescription {
  grid-area: 1 / 2 / 2 / 7;
  padding: 10px 40px 0px 18px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0 15px 15px 0;
  border-left: none;
  min-height: 300px;
  @media (max-width: 700px) {
    min-height: auto;
    padding: 10px 15px 0px 18px;
  }


  h3 {
    width: 100%;
    text-align: start;
    margin-bottom: 5px;
    font-size: 1.4rem;
    color: #138f27;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
  }

  p {
    padding-top: 10px;
    text-indent: 30px;
    text-align: start;
    font-size: 1.18rem;
    margin-bottom: 2px;
    color: #1a1a1a;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300;
    padding-bottom: 5px;
    @media (max-width: 900px) {
      padding-top: 0px;
    }
  }

  a {
    color: #34ab03;
    display: block;
    font-size: 1.2rem;
    text-decoration: none;
    text-align: end;
    margin-bottom: 15px;
    padding: 4px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    @media (max-width: 900px) {
      margin-bottom: 0px;
    }
  }

  .blockForOtherInfo {
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    bottom: 0px;
    justify-content: space-between;
    padding: 10px 40px 0px 5px;
    flex-grow: 3;
    @media (max-width: 700px) {

      padding: 10px 40px 10px 5px;
    }

    &:before {
      width: 90%;
      left: 5%;
      content: "";
      display: block;
      height: 1px;
      background-color: #011b52;
      position: absolute;
      top: 0;
    }

    .like {
      column-gap: 10px;
      padding-left: 15px;
      top: 5px;
      position: relative;
      font-size: 1.3rem;
      padding-right: 15px;
      display: flex;
      align-items: center;
      @media (max-width: 1000px) {
        top: 2px;
      }

      span {
        position: relative;
        //top: -3px;
      }

      .likeIcon {
        color: #9B969D;
        transition: 700ms ease;

        &.redColor {
          color: #ff3737;
          scale: 1.1;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

  }
}

