.container {
  background-color: rgba(32, 34, 46, 0.83);
  padding: 10px 20px;
  position: fixed;
  right: 40px;
  border-radius: 10px;
  transition: all 800ms ease-in-out;
  box-shadow: 0px 0px 0px 1px inset #707070;
  z-index: 2000;
  @media (max-width: 800px) {
    padding: 3px 20px;
    right: 10px;
  }

  .arrowToTopIcon {
    font-size: 1.4rem;
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: #0753c5;
  }
}
