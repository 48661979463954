@function responsive($size-at-min-width, $size-at-max-width) {
  $min-width: 360px;
  $max-width: 1200px;

  // Sprawdzenie jednostek i przypisanie domyślnej jednostki 'px', jeśli nie podano
  @if unitless($size-at-min-width) {
    $size-at-min-width: $size-at-min-width * 1px;
  }
  @if unitless($size-at-max-width) {
    $size-at-max-width: $size-at-max-width * 1px;
  }

  $slope: calc(calc($size-at-max-width - $size-at-min-width) / calc($max-width - $min-width));
  $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
  $return-value: clamp(
          #{$size-at-min-width},
          #{$y-axis-intersection} + #{$slope} * 100vw,
          #{$size-at-max-width}
  );

  @return $return-value;
}

@function r($size-at-min-width, $size-at-max-width) {
  @return responsive($size-at-min-width, $size-at-max-width);
}


.container {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 10px 1px #95959536;

  &.winter {
    background-image: url("./../../assets/images/snow.jpg");
  }

  &.summer {
    background-image: url("./../../assets/images/beige.jpg");
  }

  .content {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: responsive(20, 40px) responsive(40, 280px)  responsive(20, 40px) var(--leftAndRightPadding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
    cursor: pointer;
  }

  //padding: responsive(5, 15px) 150px responsive(80px, 80) var(--leftAndRightPadding);


  @media(max-width: 550px) {

    justify-content: center;
  }

  h3 {
    color: #457c4d;
    font-size: 2rem;
    text-transform: uppercase;
    margin-right: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
  }
}

.mainBtn {
  display: block;

  @media(max-width: 500px) {
    display: none;
  }
}
