body {
    margin: 0;

}

* {
    &:focus {
        transition: 0ms;
        outline: 2px solid red;
    }
}
