.container {
  padding: 20px var(--leftAndRightPadding) 20px var(--leftAndRightPadding);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 25px;
}
@media (max-width: 1000px) {
  .container {
    padding: 25px var(--leftAndRightPadding);
    row-gap: 20px;
  }
}
.container h2 {
  font-size: 2rem;
  text-transform: uppercase;
}
.container .containerForCards {
  width: 85%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (max-width: 1000px) {
  .container .containerForCards {
    row-gap: 15px;
    width: 100%;
  }
}
.container .blockForBtn {
  display: flex;
  width: 100%;
  justify-content: center;
}

.mainBtn {
  width: 45%;
  background-color: #E5C201;
  padding: 8px;
}

@media (max-width: 700px) {
  .arrowBack {
    top: 100px !important;
  }
}

.AllProjects {
  padding: 100px var(--leftAndRightPadding) 20px var(--leftAndRightPadding) !important;
}
@media (max-width: 700px) {
  .AllProjects {
    padding-top: 50px !important;
  }
}


