.container {
  display: flex;
  column-gap: 25px;
  width: 60%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1400px) {
    display: none;
  }

  a, span {

    font-size: 1.2rem;
    //  color: #30a1b5;
    color: #1c6700;
    text-shadow: 0px 0px 2px #cecece;
    transition: all 100ms ease-in-out;
    text-decoration: underline;
    text-decoration-color: transparent;
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;

    &:hover {
      cursor: pointer;
      // color: #3dc9e1;
      color: #103e00;
      text-decoration: underline;
      text-decoration-color: #6ed9a8;
    }
  }
}

.hamburger {
  display: none;
  @media (max-width: 1400px) {
    display: block;
    &:focus-within {
      outline: 2px solid red;
    }
  }
}
