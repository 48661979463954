.mainContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }

  .blockForTitleAndOther {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      width: 100%;
    }

    .blockForDiamond {
      background-color: #4444ff;
      display: flex;
      justify-content: center;
      width: 45%;
      border-radius: 40px;
      margin: 20px 0;
      border: 1px inset #002977;
      box-shadow: 0px 0px 10px 4px inset #4d4d4d;
      padding: 10px 0;
      @media (max-width: 900px) {
        width: 25%;
        margin: 10px 0;
        border-radius: 25px;
      }
      @media (max-width: 600px) {
        width: 30%;
      }
      @media (max-width: 450px) {
        width: 35%;
      }
      @media (max-width: 380px) {
        width: 40%;
      }


      .diamondIcon {
        color: #ffffff;
        font-size: 8rem;
        filter: drop-shadow(2px 2px 2px #16161c);
        @media (max-width: 750px) {
          font-size: 6rem;
        }
        @media (max-width: 450px) {
          font-size: 5rem;
        }
      }
    }

    h4 {
      font-size: 1.4rem;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .blockForHowMoney {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mainSpan {
        font-weight: bold;
        font-size: 1.3rem;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }

  .blockForOnlineDonat {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    @media (max-width: 900px) {
      width: 100%;
      row-gap: 10px;
    }

    h5 {
      text-transform: uppercase;
      font-size: 1.2rem;
    }

    button {
      width: 40%;
      color: white;
      text-shadow: 1px 0px 1px #2c2c2c;
      background-color: #4444FF;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px inset blue;
      font-size: 0.8rem;
      transition: 250ms;
      @media (max-width: 700px) {
        width: 90%;
      }

      &:hover {
        cursor: pointer;
        background-color: #2828ea;
      }
    }

    p {
      border-top: 1px solid rgba(84, 84, 84, 0.75);
      padding: 10px 5px;
      font-size: 1.1rem;
      color: black;
    }
  }
}

.arrow {
  top: 70px;
  @media (max-width: 700px) {
    top: 30px;
  }
}
