:root {
  --leftAndRightPadding: 120px;
  --fontSizeInFooter: 0.9rem;
  --fontSizeInFooterHeadlines: 1.1rem;
  --changeToMobile: (max-width: 700px);
  @media (max-width: 1000px) {
    --leftAndRightPadding: 60px;
  }
  @media (max-width: 800px) {
    --leftAndRightPadding: 50px !important;
  }
  @media (max-width: 500px) {
    --leftAndRightPadding: 18px !important;
  }
}



