.blockForLogoAndNav {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  transition: top 450ms, padding 350ms, height 300ms;
  position: fixed;
  width: 100vw;
  z-index: 2001;
  align-items: center;
  box-shadow: 0px 0px 10px 1px inset #d7d7d79c;

  .content {
    max-width: 1440px;
    width: 100%;
  }


  .navContainer {
    transition: 300ms;

    padding: 15px 0;
    display: flex;
    justify-content: space-around;
  }

  @media (max-width: 1400px) {
    top: 0px;
  }
}

.emptyBlock {

  transition: 500ms;
  opacity: 0.5;
  width: 100%;
  float: left;
  height: 50px;
  display: block;
}

.hamburger {
  height: 0;
  transition: height 400ms ease-in-out, top 300ms;
  overflow-y: hidden;
  width: 100vw;
  z-index: 10;

  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  position: absolute;
  top: 70px;
  box-shadow: 0px 0px 5px 2px inset #949dbd;

  > div {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
  }


  a, span {
    &:first-of-type {
      padding-top: 10px;
    }

    box-sizing: border-box;
    padding: 3px;
    font-size: 1.3rem;
    letter-spacing: 1px;
    color: #3a3a3a;
    display: block;

    width: 100%;
    text-align: center;
    transition: all 200ms ease-in-out;
    text-decoration: underline;
    text-decoration-color: transparent;
    font-weight: normal;
    font-family: robotoBold, cursive;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: black;
    }
  }

  pointer-events: none;

  &.hamburgerOpen {
    pointer-events: auto;
    height: min-content;
    @media (min-width: 1400px) {
      height: 0px;
    }
  }

}

