.mainContainer {
  padding: 40px var(--leftAndRightPadding);
  max-width: min(1440px, 100%);
  margin: 0 auto;

  h2 {
    width: 100%;
    color: #0e8819;
    font-size: 2rem;
    text-transform: uppercase;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    text-align: start;
  }

  .container {
    display: flex;
    row-gap: 25px;
    flex-direction: column;
    padding-top: 40px;
    justify-content: start;
    padding-bottom: 20px;
    align-items: start;
    width: 100vw;
    max-width: 100%;
  }

}
