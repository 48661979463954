.fale {
  background-image: url("../../../assets/images/fale.webp");
  width: 100%;
  height: clamp(35px, 20px + 0.0416666667 * 100vw, 70px);
  background-position: top;
  background-size: cover;
}

.container {
  background-color: #1b2f4b;
  font-family: Nunito, cursive;
  letter-spacing: 1px;
  position: relative;
}
.container .mainContainer {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 35px var(--leftAndRightPadding) 50px var(--leftAndRightPadding);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 20px;
}
@media (max-width: 700px) {
  .container .mainContainer {
    flex-direction: column;
    row-gap: 29px;
    justify-content: center;
  }
}
.container .mainContainer .blockForLogoAndContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 50px;
}
.container .mainContainer .blockForLogoAndContact p {
  width: auto;
  color: white;
  font-family: Nunito, cursive;
  letter-spacing: 1px;
  text-align: left;
  line-height: 1.8rem;
}
.container .blockForFbIcons {
  width: 30%;
  display: flex;
  justify-content: start;
  column-gap: 10px;
}
.container .blockForFbIcons .firstIconFb, .container .blockForFbIcons .secondIconFb {
  font-size: 35px;
  transition: 250ms;
  background: #1877F2;
  position: relative;
  padding-top: 1px;
}
.container .blockForFbIcons .firstIconFb:hover, .container .blockForFbIcons .secondIconFb:hover {
  cursor: pointer;
  color: #a2a2a2;
}
.container .blockForFbIcons .firstIconFb {
  color: white;
  border-radius: 100%;
}
.container .blockForFbIcons .secondIconFb {
  color: white;
  position: relative;
  z-index: 5;
  border-radius: 10px;
  display: block;
}
.container .blockForFbIcons .blockOflinkToDeclaration {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  flex-direction: column;
  row-gap: 10px;
}
.container .blockForFbIcons .blockOflinkToDeclaration span {
  color: #f8f8f8;
  font-size: 1.25rem;
  transition: 250ms;
}
.container .blockForFbIcons .blockOflinkToDeclaration span:hover {
  cursor: pointer;
  color: #cbcbcb;
}
.container .grayBelt {
  padding: 20px var(--leftAndRightPadding);
  background-color: #13243a;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(64, 64, 64, 0.2784313725);
  align-content: center;
  position: relative;
  font-family: Nunito, cursive;
}
@media (max-width: 900px) {
  .container .grayBelt {
    margin-top: 40px;
    padding: 10px var(--leftAndRightPadding);
  }
}
@media (max-width: 900px) {
  .container .grayBelt {
    padding-right: 80px;
  }
}
.container .grayBelt div {
  display: flex;
  column-gap: 10px;
}
.container .grayBelt div span, .container .grayBelt div a {
  letter-spacing: 2px;
  font-size: 0.8rem;
  color: white;
  text-shadow: 0px 1px 1px black;
}
.container .grayBelt .adminIcon {
  color: #eaeaea;
  position: absolute;
  right: 120px;
  font-size: 25px;
  text-shadow: 1px 1px 1px black;
  transition: 250ms;
  top: 20px;
}
@media (max-width: 900px) {
  .container .grayBelt .adminIcon {
    right: 74px;
    font-size: 15px;
  }
}
.container .grayBelt .adminIcon:hover {
  cursor: pointer;
  color: #fff3f3;
}

.blockOflinkToDeclaration {
  display: flex;
  gap: 10px;
}
.blockOflinkToDeclaration a {
  color: white;
  font-family: Nunito, cursive;
}

