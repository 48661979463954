.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  width: 100%;

  h4 {
    font-size: 2rem;
    color: #003680;
    text-transform: uppercase;
  }

  p {
    width: 100%;
    color: black;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.4;
  }
}
