.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.container img {
  margin-left: 10px;
  height: 400px;
}
@media (max-width: 700px) {
  .container {
    flex-direction: column;
  }
  .container img {
    height: auto;
    width: 100%;
    margin-left: 0px;
  }
}

