.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  justify-content: center;
  align-items: start;
  padding: 40px var(--leftAndRightPadding);
  border-bottom: 2px solid rgba(147, 130, 106, 0.16);
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  h2 {
    color: #457c4d;
    font-size: 2rem;
    text-transform: uppercase;
    margin-right: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
  }

  p {
    width: 100%;
    font-size: 1.2rem;
    text-align: justify;
    line-height: 1.4;
    color: #002c03;
    font-family: "Nunito", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  .containerForLinks {
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    row-gap: 10px;
  }
}
