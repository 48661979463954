.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  row-gap: 15px;
}
.container img {
  height: 350px;
}
@media (max-width: 750px) {
  .container img {
    height: 250px;
  }
}
.container span {
  color: #091a42;
  font-size: 2rem;
}
.container button {
  background-color: #93c0ff;
  font-size: 0.8rem;
  transition: 250ms;
}
.container button:hover {
  background-color: #74acff;
}

