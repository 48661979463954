.container {
  background-color: #003680;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 40px;
  box-shadow: 0px 0px 5px 1px inset #011b52;
  border-radius: 5px;
  min-height: 120px;
  @media(max-width: 1150px) {
    padding: 25px 15px;
    min-height: 100px;
  }
  @media(max-width: 1000px) {
    min-height: 70px;
    padding: 15px 15px;
  }
  @media(max-width: 750px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
  }
  @media(max-width: 450px) {
    font-size: 7px;
    padding: 8px 8px;
  }

  &:hover {
    cursor: pointer;
  }

  h3 {
    text-align: start;
    width: 90%;
    color: white;
    font-size: 1.5rem;
    margin-right: 10px;
    @media (max-width: 750px) {
      width: 100%;
      text-align: center;
    }
  }
}


.buttonInProjectCard {
  background-color: #E5C201;
  @media (max-width: 800px) {
    background-color: rgb(106 146 254);
  }
}

.iconEyes {
  font-size: 1.5rem;
  color: #faf9f9;
}
