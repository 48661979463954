.container {
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  border: 1.4px solid rgba(49, 3, 3, 0.4);
  background-color: rgba(192, 192, 192, 0.1019607843);
  box-shadow: 0 0 2px 1px rgba(106, 106, 106, 0.1019607843);
  display: flex;
  align-items: flex-start;
}
@media (max-width: 1000px) {
  .container {
    background-image: none;
  }
}
@media (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.container .noImage {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(171, 171, 171, 0.3294117647);
  font-size: 70px;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.4);
  margin: 10px;
}
.container .imageBook {
  position: relative;
  width: 100%;
}
.container .imageBook img:first-of-type {
  z-index: 100;
  position: relative;
}
.container .imageBook img:last-of-type {
  z-index: 99;
  width: 77%;
  height: 52%;
  position: absolute;
  left: 13%;
  top: 22%;
  object-fit: cover;
}
.container .blockForImgAndSomeInfo {
  grid-area: 1/1/2/2;
  width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  row-gap: 5px;
}
@media (max-width: 800px) {
  .container .blockForImgAndSomeInfo {
    width: 100%;
  }
}
.container .blockForImgAndSomeInfo img {
  width: 100%;
  max-height: 80%;
}
.container .blockForImgAndSomeInfo .blockForSpans {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 9999;
}
.container .blockForImgAndSomeInfo .blockForSpans .blockForSpan {
  display: flex;
  column-gap: 5px;
  padding-left: 10px;
}
.container .blockForImgAndSomeInfo .blockForSpans .blockForSpan .spanCategoryAndWhenPublished {
  color: #343434;
  padding-bottom: 4px;
  width: 120px;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
.container .blockForImgAndSomeInfo .blockForSpans .blockForSpan .mainInfoSpan {
  color: #4b4b4b;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  padding-left: 2px;
}

.btnReadMore {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.containerForDescription {
  grid-area: 1/2/2/7;
  padding: 10px 40px 0px 18px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0 15px 15px 0;
  border-left: none;
  min-height: 300px;
}
@media (max-width: 700px) {
  .containerForDescription {
    min-height: auto;
    padding: 10px 15px 0px 18px;
  }
}
.containerForDescription h3 {
  width: 100%;
  text-align: start;
  margin-bottom: 5px;
  font-size: 1.4rem;
  color: #138f27;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
.containerForDescription p {
  padding-top: 10px;
  text-indent: 30px;
  text-align: start;
  font-size: 1.18rem;
  margin-bottom: 2px;
  color: #1a1a1a;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  padding-bottom: 5px;
}
@media (max-width: 900px) {
  .containerForDescription p {
    padding-top: 0px;
  }
}
.containerForDescription a {
  color: #34ab03;
  display: block;
  font-size: 1.2rem;
  text-decoration: none;
  text-align: end;
  margin-bottom: 15px;
  padding: 4px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
@media (max-width: 900px) {
  .containerForDescription a {
    margin-bottom: 0px;
  }
}
.containerForDescription .blockForOtherInfo {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  bottom: 0px;
  justify-content: space-between;
  padding: 10px 40px 0px 5px;
  flex-grow: 3;
}
@media (max-width: 700px) {
  .containerForDescription .blockForOtherInfo {
    padding: 10px 40px 10px 5px;
  }
}
.containerForDescription .blockForOtherInfo:before {
  width: 90%;
  left: 5%;
  content: "";
  display: block;
  height: 1px;
  background-color: #011b52;
  position: absolute;
  top: 0;
}
.containerForDescription .blockForOtherInfo .like {
  column-gap: 10px;
  padding-left: 15px;
  top: 5px;
  position: relative;
  font-size: 1.3rem;
  padding-right: 15px;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .containerForDescription .blockForOtherInfo .like {
    top: 2px;
  }
}
.containerForDescription .blockForOtherInfo .like span {
  position: relative;
}
.containerForDescription .blockForOtherInfo .like .likeIcon {
  color: #9B969D;
  transition: 700ms ease;
}
.containerForDescription .blockForOtherInfo .like .likeIcon.redColor {
  color: #ff3737;
  scale: 1.1;
}
.containerForDescription .blockForOtherInfo .like .likeIcon:hover {
  cursor: pointer;
}

