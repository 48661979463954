
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px var(--leftAndRightPadding);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 1100px 40px;
  max-width: 1440px;
  margin: 0 auto;

  &.winter {

    background-image: url("./../../../../assets/images/snowflake.png");
  }

  &.summer {
    background-image: url("./../../../../assets/images/lines.svg");
    background-size: 40vw;

    background-position: top right;
  }



  .img {
    margin-left: 10px;
    height: 450px;
    border-radius: 10px;
    @media (max-width: 1100px) {
      height: 300px;
    }
  }
}
