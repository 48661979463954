@import "./../../../../mixin";

.container {
  position: relative;
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  height: min-content;
  padding: 40px var(--leftAndRightPadding);
  @media (max-width: 650px) {
    flex-direction: column;
    row-gap: 10vw;
  }

  .left {
    width: 42.5%;
    position: relative;
    @media (max-width: 650px) {
      width: 100%;
    }


    img {
      max-width: 100%;
    }

    .playPauseButton {
      position: absolute;
      top: 0px;
      left: 0px;
      background: #d6d8e0 !important;
      color: #003dbd;
      border: none;
      padding: 10px;
      border-radius: 23%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;

      cursor: pointer;
      transition: background-color 200ms;
      z-index: 10;
      height: 40px;

      &:hover {
        background: #003680 !important;

        svg {
          color: #ffffff;
        }
      }

      svg {
        font-size: 20px;
      }
    }
  }

  .right {
    width: 58%;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1.2vw;
    @media (max-width: 650px) {
      padding: 0 0px;
      width: 100%;
      justify-content: space-between;
      row-gap: 4vw;
    }

    .block {
      width: 43%;
      //
      // height: 127px;
      padding: 10px;
      display: flex;
      aspect-ratio: 1/0.485;
      @media (max-width: 1000px) {
        aspect-ratio: 1/0.49;
        padding: 8px;
      }
      @media (max-width: 700px) {
        aspect-ratio: 1/0.54;
        padding: 4px;
      }

      column-gap: 16px;
      box-shadow: 0 0 5px 2px #d5d5d577;
      align-items: center;
      transition: 300ms;

      &:hover {
        cursor: pointer;
        //   background: whitesmoke;

        .text svg {
          left: 20px;
        }
      }

      .image {

        height: 90%;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(211, 173, 106, 0.36);
        @media (max-width: 650px) {
          height: 70%;
        }

        img {
          width: 80%;
          height: 80%;
        }

        svg {

          color: #1848b9;
          font-size: clamp(10px, 3.5vw + 18px, 63px);


        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70%;
        color: #3ea347;
        font-family: Nunito, cursive;
        font-weight: 300;

        h5 {
          text-align: left;
          text-transform: uppercase;
          letter-spacing: 0px;
          font-size: responsive(4, 17px);
          @media (max-width: 650px) {
            font-size: responsive(10, 17px);
          }

        }

        svg {
          position: relative;
          transition: 300ms;
          color: #d3ad6a;
          width: 20px;
          left: 0;
        }
      }

    }
  }
}

.oneImage {
  overflow: hidden;
}

.sliderImage {
  position: relative;
  aspect-ratio: 1/1;

  & > div {

    display: flex !important;
    justify-content: center !important;
    width: 100%;
  }

  img {
    &:first-of-type {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(30px);
      z-index: 10;
    }

    &:last-of-type {
      position: absolute;

      height: 100%;
      object-fit: contain;

      z-index: 12;

    }

  }
}
