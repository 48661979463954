.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  img {
    margin-left: 10px;
    height: 400px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    img {
      height: auto;
      width: 100%;
      margin-left: 0px;
    }
  }


}

