.container {
  padding-top: 90px;
  display: flex;
  justify-content: center;

  h2 {
    padding-bottom: 20px;
  }

  .wrapper {
    max-width: 1300px;
    width: 90%;
    display: flex;
    flex-direction: column;

    .images {
      display: flex;
      row-gap: 10px;
      column-gap: 10px;
      justify-content: center;
      flex-wrap: wrap;

      padding: 0 var(--leftAndRightPadding) 100px var(--leftAndRightPadding);

      button {
        border: none;
        background: none !important;
      }
    }

    img {
      cursor: pointer;
      max-width: 130px;
    }
  }
}

//.image {
//  position: fixed;
//  margin-right: 13%;
//  margin-left: 13%;
//  background: rgba(31, 31, 31, 0.86);
//  z-index: 11999;
//  display: flex;
//  justify-content: space-around;
//  align-items: center;
//  height: 80%;
//  top: 10%;
//
//  border-radius: 20px;
//
//  img {
//    max-width: 85%;
//    max-height: 90%;
//  }
//
//  .arrow {
//    background: transparent;
//    color: whitesmoke;
//    font-size: 30px;
//    padding: 0;
//    border: none;
//    margin: 3px;
//    @media (max-width: 700px) {
//      font-size: 16px;
//
//    }
//  }
//
//  .close {
//    position: absolute;
//    top: 5px;
//    right: 5px;
//    cursor: pointer;
//    font-size: 30px;
//    color: whitesmoke;;
//    background: transparent;
//    border: none;
//  }
//}
