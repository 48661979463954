.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7e9af6;
}
.container .containerForBackGroundAndNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container .containerForBackGroundAndNumber .containerForBackGround {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 5002;
  background-image: url("../../assets/images/sad500Cat.png");
  background-repeat: no-repeat;
  background-size: 300px;
  opacity: 1;
  background-position: center;
}
@media (max-width: 800px) {
  .container .containerForBackGroundAndNumber .containerForBackGround {
    background-size: 200px;
  }
}
@media (max-width: 550px) {
  .container .containerForBackGroundAndNumber .containerForBackGround {
    background-size: 100px;
  }
}
@media (max-width: 370px) {
  .container .containerForBackGroundAndNumber .containerForBackGround {
    background-size: 65px;
  }
}
.container .containerForBackGroundAndNumber .spanErrorNumber {
  font-size: 27rem;
  letter-spacing: 40px;
  position: relative;
  font-weight: bold;
  z-index: 5001;
  opacity: 0.9;
  left: 20px;
  color: #9f1717;
  text-shadow: 0px 1px 1px black;
}
@media (max-width: 800px) {
  .container .containerForBackGroundAndNumber .spanErrorNumber {
    font-size: 20rem;
  }
}
@media (max-width: 550px) {
  .container .containerForBackGroundAndNumber .spanErrorNumber {
    font-size: 11rem;
  }
}
@media (max-width: 370px) {
  .container .containerForBackGroundAndNumber .spanErrorNumber {
    font-size: 7rem;
  }
}
.container button {
  margin-top: 15px;
  width: 250px;
  background-color: #f0eeee;
  border: 2px inset #4869d9;
  color: #0030ce;
  font-size: 0.8rem;
  letter-spacing: 2px;
  transition: 250ms;
  text-shadow: 0px 1px 1px #444444;
}
@media (max-width: 370px) {
  .container button {
    width: 200px;
  }
}
.container button:hover {
  background-color: #4b73f5;
  color: white;
}
.container .mainSpan {
  width: 50%;
  text-align: center;
  font-size: 1.1rem;
  color: #1f1f1f;
  text-shadow: 0px 1px 1px black;
}


