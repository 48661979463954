.container {
  background-color: #e82323;
}

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0ms;
  font-family: Nunito, cursive;

  @media (max-width: 1400px) {

    &:last-child {

      padding-bottom: 10px;
    }
  }

  &:hover {
    cursor: pointer;
    color: #061b00;
  }

  a {
    font-family: Nunito, cursive;
    padding: 2px;

    &:hover {
      cursor: pointer;
      color: #081c00;
    }


  }

  @media (max-width: 1400px) {
    flex-direction: row;

  }

  .subMenu {
    transition: 0ms;
    display: flex;
    visibility: hidden;
    min-height: 20px;
    min-width: 200px;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    z-index: 99;
    top: 0;
    @media (max-width: 1400px) {
      visibility: visible;
      position: relative;
      min-height: 0;

    }

    div {
      transition: 0ms;
      background: #ffffff;
      position: relative;
      top: 40px;
      z-index: 100;

      @media (max-width: 1400px) {
        top: 0px;
        height: 0;
        overflow-y: hidden;
      }

      a, span {
        transition: 0ms;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30px;
      }
    }
  }

  &:hover, &:focus-within {
    .subMenu {
      .arrow {
        display: inline;
      }

      visibility: visible;
      @media (max-width: 1400px) {
        position: static;
        div {
          height: auto;
        }
      }
    }
  }
}

.arrow {
  display: none;
  margin-left: 10px;
  transform: rotate(90deg);
}
