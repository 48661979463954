.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 15px;
  width: 25%;
}
@media (max-width: 100px) {
  .container {
    position: absolute;
    bottom: 60px;
    left: 60px;
  }
}
@media (max-width: 800px) {
  .container {
    left: 50px;
  }
}
@media (max-width: 700px) {
  .container {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .container h4 {
    order: 1;
  }
  .container .qrCode {
    order: 2;
  }
}
@media (max-width: 500px) {
  .container {
    left: 18px;
  }
}
.container h4 {
  color: white;
  font-size: var(--fontSizeInFooterHeadlines);
  font-family: Nunito, cursive;
}
@media (max-width: 700px) {
  .container h4 {
    font-size: 20px;
  }
}

.qrCode {
  height: auto;
  max-width: 100%;
  width: 60%;
}
@media (max-width: 900px) {
  .qrCode {
    width: 25%;
  }
}

