.backgroundWithTitle {
  padding: 45px 0;
  width: 100%;
  //background-image: url("../../../assets/images/makeAdonat.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 700px) {
    margin-bottom: 20px;
  }

  h2 {
    text-align: center;
    color: white;
    text-shadow: 0px 1px 1px black;
    font-size: 2rem;
    padding-top: 50px;
    text-transform: uppercase;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
  }

  .containerForContentAndBtn {
    width: 65%;
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    @media (max-width: 700px) {
      width: 90%;

    }

    p {
      align-items: start;
    }

    p {
      color: black;
      font-size: 1.2rem;
      line-height: 1.4;
      @media (max-width: 700px) {

        padding-bottom: 10px;
      }
    }

    .blockForBtn {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  img {
    height: 400px;
    @media (max-width: 700px) {
      height: auto;
      width: 100%;
      padding-top: 20px;
    }
  }
}
