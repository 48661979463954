.container {
  background-color: #001f59;
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0px;
  top: 25%;
  z-index: 90000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 700px) {
  .container {
    top: 30.5%;
  }
}
.container .block {
  pointer-events: none;
}
.container .block.open {
  pointer-events: auto;
  opacity: 1;
}
.container:focus-within .block, .container .block.open {
  pointer-events: auto;
  opacity: 1;
}

.icon {
  font-size: 38px;
  color: white;
}

.block {
  opacity: 0;
  position: absolute;
  right: clamp(-470px, (100vw - 70px) * -1, 0px);
  top: 0px;
  max-width: calc(100vw - 70px);
  width: 470px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border: 2px solid black;
}
.block button {
  transition: 0ms;
  width: 100%;
  border-radius: 0;
  background: transparent;
  border: none;
  color: black;
}

