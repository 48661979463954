.container {
  font-family: Nunito, sans-serif;
  padding-top: 85px;
  display: flex;
  justify-content: center;
}
.container img {
  max-width: 90%;
}
.container hr {
  display: none;
}
.container .noImage {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(171, 171, 171, 0.3294117647);
  font-size: 70px;
  border-radius: 10px;
  margin: 10px;
}
.container .noImage img {
  border-radius: 10px;
}
.container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  max-width: 1300px;
  padding-bottom: 70px;
}
.container .content .titleContainer {
  width: 100%;
}
.container .content .header {
  display: flex;
  align-items: center;
  width: 100%;
}
.container .content .header .headImage {
  width: 100%;
  max-width: 30%;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media (max-width: 700px) {
  .container .content .header {
    flex-direction: column;
  }
  .container .content .header .headImage {
    max-height: 200px;
    width: auto;
    max-width: 90%;
  }
}
.container .content .header h2 {
  width: 100%;
  padding-left: 5%;
  padding-right: 12%;
  text-align: center;
  padding-bottom: 10px;
  font-family: Nunito, sans-serif;
  position: relative;
  font-size: 1.8rem;
  box-sizing: border-box;
  color: #1c6700;
  margin-bottom: 20px;
}
@media (max-width: 700px) {
  .container .content .header h2 {
    padding-left: 0%;
    padding-right: 0%;
  }
}
.container .content .header h2:after {
  content: "";
  height: 1px;
  width: 70%;
  background: #1c6700;
  left: 10%;
  position: absolute;
  bottom: 0px;
}
.container .content p {
  text-align: left;
  padding-top: 10px;
  text-indent: 40px;
  width: 100%;
  font-size: 1.3rem;
}

.blockForInformation {
  display: flex;
  justify-content: flex-start;
  padding-left: 11%;
  column-gap: 30px;
  font-size: 1.3rem;
}
@media (max-width: 700px) {
  .blockForInformation {
    row-gap: 10px;
    flex-wrap: wrap;
    padding-left: 0%;
  }
}
.blockForInformation .blockForSpan .spanCategoryAndWhenPublished {
  font-weight: bold;
  padding-right: 15px;
  color: #1c6700;
}
.blockForInformation .blockForSpan .mainInfoSpan {
  color: #464646;
  text-shadow: 0px 0px 1px #114300;
}

.like {
  display: flex;
  column-gap: 10px;
  padding-left: 15px;
  top: 0;
  position: relative;
  font-size: 1.3rem;
  padding-right: 15px;
}
.like span {
  position: relative;
  top: -1px;
}
.like .likeIcon {
  color: #9B969D;
  transition: 700ms ease;
}
.like .likeIcon.redColor {
  color: #ff3737;
  scale: 1.1;
}
.like .likeIcon:hover {
  cursor: pointer;
}

