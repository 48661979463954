.container {
  background-color: #542a0e;
  padding: 10px var(--leftAndRightPadding);
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 2000;
  top: 0px;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1440px;
  }

  /*
    @media (max-width: 700px) {
      display: none;
    }*/

  .blockForContact {
    display: flex;
    column-gap: 10px;

    .iconPhone {
      font-size: 0.95rem;
      opacity: 0.5;
      color: #ffffff;
    }

    .tegForContact {
      color: white;
      font-size: 1rem;
      text-decoration: none;
      font-family: robotoBold, cursive;
    }
  }

  .blockForQuote {
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;

    img {
      width: 22px;
    }

    span {
      color: white;
      text-shadow: 0px 0px 1px #bdbebe;
    }
  }
}
