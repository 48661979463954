@import "./../../../mixin";

.fale {
  background-image: url("../../../assets/images/fale.webp");
  width: 100%;
  height: responsive(35px, 70px);
  background-position: top;
  background-size: cover;
}

.container {
  background-color: #1b2f4b;
  font-family: Nunito, cursive;
  letter-spacing: 1px;
  position: relative;


  .mainContainer {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 35px var(--leftAndRightPadding) 50px var(--leftAndRightPadding);
    //  background-color: #93826a;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    row-gap: 20px;
    @media (max-width: 700px) {
      flex-direction: column;
      row-gap: 29px;
      justify-content: center;
    }

    .blockForLogoAndContact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 50px;

      p {
        width: auto;
        color: white;
        font-family: Nunito, cursive;
        letter-spacing: 1px;
        text-align: left;
        line-height: 1.8rem;
      }
    }

    //
    //.fb2 {
    //  position: relative;
    //
    //  &:after {
    //    background: #1877F2;
    //    width: 100%;
    //    height: 95%;
    //    display: block;
    //    z-index: 4;
    //    content: "";
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    border-radius: 9px;
    //  }
    //}
  }

  .blockForFbIcons {
    width: 30%;
    display: flex;
    justify-content: start;
    column-gap: 10px;

    .firstIconFb, .secondIconFb {
      font-size: 35px;
      transition: 250ms;
      background: #1877F2;
      position: relative;
      padding-top: 1px;

      &:hover {
        cursor: pointer;
        color: #a2a2a2;
      }
    }

    .firstIconFb {
      color: white;
      border-radius: 100%;
      //  filter: drop-shadow(0px 1px 1px #282828);
    }

    .secondIconFb {
      color: white;
      position: relative;
      z-index: 5;
      border-radius: 10px;
      display: block;

    }

    .blockOflinkToDeclaration {
      display: flex;
      justify-content: flex-end;
      width: 30%;
      flex-direction: column;
      row-gap: 10px;

      span {
        color: #f8f8f8;
        font-size: 1.25rem;
        transition: 250ms;

        &:hover {
          cursor: pointer;
          color: #cbcbcb;
        }
      }
    }
  }

  .grayBelt {
    padding: 20px var(--leftAndRightPadding);
    background-color: #13243a;
    display: flex;
    justify-content: center;
    border-top: 1px solid #40404047;
    align-content: center;
    position: relative;
    font-family: Nunito, cursive;
    @media (max-width: 900px) {
      margin-top: 40px;
      padding: 10px var(--leftAndRightPadding);
    }
    @media (max-width: 900px) {
      padding-right: 80px;
    }

    div {
      display: flex;
      column-gap: 10px;

      span, a {
        letter-spacing: 2px;
        font-size: 0.8rem;
        color: white;
        text-shadow: 0px 1px 1px black;
      }
    }

    .adminIcon {
      color: #eaeaea;
      position: absolute;
      right: 120px;
      font-size: 25px;
      text-shadow: 1px 1px 1px black;
      transition: 250ms;
      top: 20px;
      @media (max-width: 900px) {
        right: 74px;
        font-size: 15px;
      }

      &:hover {
        cursor: pointer;
        color: #fff3f3;
      }
    }
  }
}

.blockOflinkToDeclaration {
  a {
    color: white;
    font-family: Nunito, cursive;
  }

  display: flex;
  gap: 10px;
}

