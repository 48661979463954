.link {
  width: 30%;
  padding: 10px;
  background-color: white;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  border-radius: 5px;
  border: 1.6px inset #d7af6c;
  box-shadow: 0 0 1px 1px inset rgb(232 232 232 / 4);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  @media (max-width: 1100px) {
    width: 40%;
  }

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 5px;

    span {
      color: #084300;
      font-size: 1.2em;
      @media (max-width: 600px) {
        font-size: 1em;
      }
      text-shadow: 0px 0px 1px #363636;
    }

    .icon {
      color: #5b5b5b;
    }
  }
}
