.container {
  background-color: #001f59;
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0px;
  top: 25%;
  @media (max-width: 700px) {
    top: 30.5%
  }
  z-index: 90000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .block {
    pointer-events: none;

    &.open {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &:focus-within .block, .block.open {
    pointer-events: auto;
    opacity: 1;
    // visibility: visible !important;
  }
}

.icon {
  font-size: 38px;
  color: white;

}


.block {
  opacity: 0;

  position: absolute;
  right: calc(clamp(-470px, (100vw - 70px) * -1, 0px));
  top: 0px;
  max-width: calc(100vw - 70px);
  width: 470px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  border: 2px solid black;

  button {
    transition: 0ms;
    width: 100%;
    border-radius: 0;
    background: transparent;
    border: none;
    color: black;
  }
}
