.container {
  padding-top: 90px;
  display: flex;
  padding-bottom: 100px;
  justify-content: center;
}
.container .wrapper {
  position: relative;
  max-width: 1300px;
  width: 90%;
  display: flex;
  flex-direction: column;
}
.container .year .gallery img {
  max-width: 55px;
}

