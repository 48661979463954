.container {
  padding: 40px var(--leftAndRightPadding);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 25px;
  width: 100%;
  height: 500px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  @media(max-width: 1000px) {
    padding: 20px var(--leftAndRightPadding);
    row-gap: 15px;
  }
  @media(max-width: 800px) {
    padding: 12px var(--leftAndRightPadding);
    row-gap: 8px;
  }

  .map {
    width: 100%;
    height: 400px;
    @media(max-width: 1000px) {
      height: 350px;
    }
    @media(max-width: 800px) {
      height: 250px;
    }
    @media(max-width: 600px) {
      height: 180px;
    }
    @media(max-width: 500px) {
      height: 150px;
    }
    @media(max-width: 400px) {
      height: 120px;
    }
  }

  h2 {
    width: 100%;
    text-align: start;
    font-size: 2rem;
    text-transform: uppercase;
    color: #457c4d;
    margin-right: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
  }
}
