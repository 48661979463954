.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 15px;
  width: 25%;
  @media (max-width: 100px) {
    position: absolute;
    bottom: 60px;
    left: 60px;
  }
  @media (max-width: 800px) {
    left: 50px;
  }
  @media (max-width: 700px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    h4 {
      order: 1;
    }
    .qrCode {
      order: 2;
    }
  }
  @media (max-width: 500px) {
    left: 18px;
  }


  h4 {
    color: white;
    font-size: var(--fontSizeInFooterHeadlines);
    font-family: Nunito, cursive;
    @media (max-width: 700px) {
      font-size: 20px;
    }
  }
}

.qrCode {
  height: auto;
  max-width: 100%;
  width: 60%;
  @media (max-width: 900px) {
    width: 25%;
  }
}
