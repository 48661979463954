
.container {
  font-family: Nunito, sans-serif;

  img {
    max-width: 90%;
  }

  hr {
    display: none;
  }

  //background-color: #f5f5f5;
  padding-top: 85px;
  display: flex;
  justify-content: center;

  .noImage {
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ababab54;
    font-size: 70px;
    border-radius: 10px;

    img {
      border-radius: 10px;
    }

    // border: 1px solid #bdbdbd66;
    margin: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    max-width: 1300px;
    padding-bottom: 70px;

    .titleContainer {
      width: 100%;
    }

    .header {
      display: flex;
      align-items: center;
      width: 100%;

      .headImage {
        width: 100%;
        max-width: 30%;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      @media (max-width: 700px) {
        flex-direction: column;
        .headImage {

          max-height: 200px;
          width: auto;
          max-width: 90%;
        }
      }

      h2 {
        width: 100%;
        padding-left: 5%;
        padding-right: 12%;
        text-align: center;
        padding-bottom: 10px;
        font-family: Nunito, sans-serif;
        position: relative;
        font-size: 1.8rem;
        box-sizing: border-box;
        color: #1c6700;
        @media (max-width: 700px) {
          padding-left: 0%;
          padding-right: 0%;
        }

        &:after {
          content: "";
          height: 1px;
          width: 70%;
          background: #1c6700;
          left: 10%;
          position: absolute;
          bottom: 0px;

        }

        margin-bottom: 20px;
      }
    }


    p {
      text-align: left;
      padding-top: 10px;
      text-indent: 40px;
      width: 100%;
      font-size: 1.3rem;
    }
  }
}

.blockForInformation {
  display: flex;
  justify-content: flex-start;
  padding-left: 11%;
  column-gap: 30px;
  font-size: 1.3rem;
  @media (max-width: 700px) {
    row-gap: 10px;
    flex-wrap: wrap;
    padding-left: 0%;
  }

  .blockForSpan {
    .spanCategoryAndWhenPublished {
      font-weight: bold;
      padding-right: 15px;
      color: #1c6700;
    }

    .mainInfoSpan {
      color: #464646;
      text-shadow: 0px 0px 1px #114300;
    }
  }
}

.like {
  display: flex;
  column-gap: 10px;
  padding-left: 15px;
  top: 0;
  position: relative;
  font-size: 1.3rem;
  padding-right: 15px;

  span {
    position: relative;
    top: -1px;
  }

  .likeIcon {
    color: #9B969D;
    transition: 700ms ease;

    &.redColor {
      color: #ff3737;
      scale: 1.1;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
