.btnKnowMore {
  &:hover {
    filter: brightness(1.1);
  }
}

button {
  border: none;
  font-size: 1rem;
  padding: 10px 25px;
  box-sizing: border-box;
  border-radius: 8px;
  transition: 400ms;
  position: relative;
  color: white;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;

  @media(max-width: 800px) {
    padding: 8px 20px;
  }
  @media(max-width: 600px) {
    padding: 8px 15px;
  }


  .emptyBlock {
    width: 100%;
    height: 100%;
    background-color: rgba(31, 31, 31, 0.09);
    position: absolute;
    top: -40px;
    left: 0;
    transition: all 400ms ease-in-out;
    opacity: 0;
    pointer-events: none;
  }

  &:hover:not(:disabled) {
    cursor: pointer;

    .emptyBlock {
      top: 0;
      opacity: 1;
    }
  }

  &:disabled {
    filter: grayscale(0.8);
    cursor: wait;
  }
}

.deerIcon {
  height: 70px;
  position: absolute;
  right: -150%; /* Obrazek zaczyna daleko poza przyciskiem */
  top: -70px;
  transform: translateY(-50%); /* Wyśrodkowanie w pionie */
  // transition: transform 1s ease-in-out;
}
