
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 15px;
  @media (max-width: 700px) {
    width: 100%;
  }

  .blockForTitleAndIcon {
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    align-items: center;

    h4 {
      color: white;
      font-size: var(--fontSizeInFooterHeadlines);
      font-family: Nunito, cursive;
    }

    .icon {
      color: white;
    }
  }

  .blockForVisits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 10px;

    span {
      color: white;
      font-size: var(--fontSizeInFooter);
    }
  }
}
