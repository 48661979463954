.container {
  position: relative;
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  height: min-content;
  padding: 40px var(--leftAndRightPadding);
}
@media (max-width: 650px) {
  .container {
    flex-direction: column;
    row-gap: 10vw;
  }
}
.container .left {
  width: 42.5%;
  position: relative;
}
@media (max-width: 650px) {
  .container .left {
    width: 100%;
  }
}
.container .left img {
  max-width: 100%;
}
.container .left .playPauseButton {
  position: absolute;
  top: 0px;
  left: 0px;
  background: #d6d8e0 !important;
  color: #003dbd;
  border: none;
  padding: 10px;
  border-radius: 23%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  transition: background-color 200ms;
  z-index: 10;
  height: 40px;
}
.container .left .playPauseButton:hover {
  background: #003680 !important;
}
.container .left .playPauseButton:hover svg {
  color: #ffffff;
}
.container .left .playPauseButton svg {
  font-size: 20px;
}
.container .right {
  width: 58%;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.2vw;
}
@media (max-width: 650px) {
  .container .right {
    padding: 0 0px;
    width: 100%;
    justify-content: space-between;
    row-gap: 4vw;
  }
}
.container .right .block {
  width: 43%;
  padding: 10px;
  display: flex;
  aspect-ratio: 1/0.485;
  column-gap: 16px;
  box-shadow: 0 0 5px 2px rgba(213, 213, 213, 0.4666666667);
  align-items: center;
  transition: 300ms;
}
@media (max-width: 1000px) {
  .container .right .block {
    aspect-ratio: 1/0.49;
    padding: 8px;
  }
}
@media (max-width: 700px) {
  .container .right .block {
    aspect-ratio: 1/0.54;
    padding: 4px;
  }
}
.container .right .block:hover {
  cursor: pointer;
}
.container .right .block:hover .text svg {
  left: 20px;
}
.container .right .block .image {
  height: 90%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(211, 173, 106, 0.36);
}
@media (max-width: 650px) {
  .container .right .block .image {
    height: 70%;
  }
}
.container .right .block .image img {
  width: 80%;
  height: 80%;
}
.container .right .block .image svg {
  color: #1848b9;
  font-size: clamp(10px, 3.5vw + 18px, 63px);
}
.container .right .block .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  color: #3ea347;
  font-family: Nunito, cursive;
  font-weight: 300;
}
.container .right .block .text h5 {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: clamp(4px, -1.5714285714px + 0.0154761905 * 100vw, 17px);
}
@media (max-width: 650px) {
  .container .right .block .text h5 {
    font-size: clamp(10px, 7px + 0.0083333333 * 100vw, 17px);
  }
}
.container .right .block .text svg {
  position: relative;
  transition: 300ms;
  color: #d3ad6a;
  width: 20px;
  left: 0;
}

.oneImage {
  overflow: hidden;
}

.sliderImage {
  position: relative;
  aspect-ratio: 1/1;
}
.sliderImage > div {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
}
.sliderImage img:first-of-type {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(30px);
  z-index: 10;
}
.sliderImage img:last-of-type {
  position: absolute;
  height: 100%;
  object-fit: contain;
  z-index: 12;
}

