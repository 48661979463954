@font-face {
  font-family: robotoLight;
  src: url("./assets/fonts/Roboto_Condensed/static/RobotoCondensed-Light.ttf");
}

@font-face {
  font-family: robotoBold;
  src: url("./assets/fonts/Roboto_Condensed/static/RobotoCondensed-ExtraBold.ttf");
}

.yarl__button {
  &:focus-visible, &:focus, &:focus-within {
    outline: 1px solid red;
  }
}

html body {
  .slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    background: #d6d8e0 !important;
    border-radius: 0 !important;
    z-index: 10 !important;

    &:focus, &:focus-within {
      outline: 1px solid red;
    }

    &:hover {
      cursor: pointer;
      background: #003680 !important;

      &::before {
        color: #ffffff;
      }
    }

    &::before {
      font-family: Arial, sans-serif;

      color: #003dbd;
    }
  }

  .slick-dots button {
    &:focus, &:focus-within {
      outline: 1px solid red;
    }

  }

  .slick-prev {

    &::before {
      content: '<' !important;
      color: #003dbd;
    }

    left: 0 !important;
    border-bottom-right-radius: 5px !important;;
    border-top-right-radius: 5px !important;;
  }


  .slick-next {
    &::before {
      content: '>' !important;
    }

    right: 0 !important;
    border-bottom-left-radius: 5px !important;;
    border-top-left-radius: 5px !important;;

  }
}

.contentInArticle {
  img {
    max-width: 100% !important;
  }
}

* {
  --yarl__container_background_color: #000000b2;
}

.grey {
  filter: grayscale(90%);
}

.App {
}

* {
  font-family: Nunito, sans-serif;
}

h6, h5, h4, h3, h2, h1, p, a {
  font-family: Nunito, sans-serif;
  margin: 0;
  text-align: center;
}

div, section, aside, nav, header, footer, main {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @media(max-width: 1100px) {
    font-size: 12px;
  }
  /*@media(max-width: 900px) {
    font-size: 10px;
  }
  @media(max-width: 450px) {
    font-size: 9px;
  }
  @media(max-width: 380px) {
    font-size: 8px;
  }*/
}

.containerForSubPage {
  padding: 100px var(--leftAndRightPadding) 40px var(--leftAndRightPadding);
  display: flex;
  flex-grow: 10;
  position: relative;

  &.withImage {
    padding-top: 0;
  }

  @media (max-width: 800px) {
    padding: 70px var(--leftAndRightPadding) 30px var(--leftAndRightPadding);
  }
}

@media (max-width: 800px) {
  input {
    font-size: 16px !important;
  }
}


.flexibleBlock {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

:root {
  --pagination-color: #5ea31f;
  --pagination-hover-color: #365e0f;


  --_access-menu-background-color: #eaeaea;

  --_access-menu-item-button-background: #222;

  --_access-menu-item-color: rgba(255, 255, 255, .6);

  --_access-menu-header-color: rgba(0, 0, 0, 0.87);

  --_access-menu-item-button-active-color: #000;

  --_access-menu-item-button-active-background-color: #fff;

  --_access-menu-div-active-background-color: #fff;

  --_access-menu-item-button-hover-color: rgba(255, 255, 255, .8);

  --_access-menu-item-button-hover-background-color: #121212;

  --_access-menu-item-icon-color: rgba(255, 255, 255, .6);

  --_access-menu-item-hover-icon-color: rgba(255, 255, 255, .8);

  --_access-menu-item-active-icon-color: #000;

}
