.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 15px;
  width: 25%;
}
@media (max-width: 700px) {
  .container {
    width: 100%;
  }
}
.container .blockForTitleAndIcon {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  align-items: center;
}
.container .blockForTitleAndIcon h4 {
  color: white;
  font-size: var(--fontSizeInFooterHeadlines);
  font-family: Nunito, cursive;
}
.container .blockForTitleAndIcon .icon {
  color: white;
}
.container .blockForOpeningHours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
}
.container .blockForOpeningHours span {
  color: white;
  font-size: var(--fontSizeInFooter);
}

