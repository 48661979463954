.container {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}
.container h2 {
  padding-bottom: 20px;
}
.container .wrapper {
  max-width: 1300px;
  width: 90%;
  display: flex;
  flex-direction: column;
}
.container .wrapper .images {
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 var(--leftAndRightPadding) 100px var(--leftAndRightPadding);
}
.container .wrapper .images button {
  border: none;
  background: none !important;
}
.container .wrapper img {
  cursor: pointer;
  max-width: 130px;
}

