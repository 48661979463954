.container {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 10px 1px rgba(149, 149, 149, 0.2117647059);
}
.container.winter {
  background-image: url("./../../assets/images/snow.jpg");
}
.container.summer {
  background-image: url("./../../assets/images/beige.jpg");
}
.container .content {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: clamp(20px, 11.4285714286px + 0.0238095238 * 100vw, 40px) clamp(40px, -62.8571428571px + 0.2857142857 * 100vw, 280px) clamp(20px, 11.4285714286px + 0.0238095238 * 100vw, 40px) var(--leftAndRightPadding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
  cursor: pointer;
}
@media (max-width: 550px) {
  .container {
    justify-content: center;
  }
}
.container h3 {
  color: #457c4d;
  font-size: 2rem;
  text-transform: uppercase;
  margin-right: 10px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}

.mainBtn {
  display: block;
}
@media (max-width: 500px) {
  .mainBtn {
    display: none;
  }
}

