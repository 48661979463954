.container {
  background-color: #360b0b;
  padding: 40px var(--leftAndRightPadding);
  @media (max-width: 950px) {
    padding: 15px var(--leftAndRightPadding);
  }

  h2 {
    width: 100%;
    text-align: start;
    color: #003680;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .shortDescriptionUnderHeadline {
    margin-top: 10px;
    color: black;
    line-height: 1.1;
    font-size: 1.2rem;

    > * {
      text-align: start;
    }

    @media (max-width: 950px) {
      margin-top: 5px;
    }

  }

  .containerForActivities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 25px;
    row-gap: 25px;
    width: 100%;
    padding: 40px 0;
    @media (max-width: 900px) {
      row-gap: 10px;
      column-gap: 10px;
      justify-content: center;
      padding: 20px 0;
    }
  }

  .blockForBtn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
