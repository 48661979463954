.container {
  display: flex;
  column-gap: 25px;
  width: 60%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1400px) {
  .container {
    display: none;
  }
}
.container a, .container span {
  font-size: 1.2rem;
  color: #1c6700;
  text-shadow: 0px 0px 2px #cecece;
  transition: all 100ms ease-in-out;
  text-decoration: underline;
  text-decoration-color: transparent;
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}
.container a:hover, .container span:hover {
  cursor: pointer;
  color: #103e00;
  text-decoration: underline;
  text-decoration-color: #6ed9a8;
}

.hamburger {
  display: none;
}
@media (max-width: 1400px) {
  .hamburger {
    display: block;
  }
  .hamburger:focus-within {
    outline: 2px solid red;
  }
}

