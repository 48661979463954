.container {
  background-color: white;
  width: 30%;
  padding: 15px 15px 25px 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 5px;
  border: 1px solid #003680;
  box-shadow: 0px 0px 1px 2px inset rgba(163, 191, 224, 0.66);
  transition: 250ms;
  @media (max-width: 750px) {
    width: 40%;
    row-gap: 8px;
    padding: 10px 10px 15px 10px;
  }
  @media (max-width: 450px) {
    width: 48%;
    row-gap: 8px;
  }

  h4 {
    color: #003680;
    position: relative;
    font-size: 1.3rem;

    &:after {
      content: "";
      display: flex;
      width: 100%;
      height: 1px;
      background-color: rgba(160, 160, 169, 0.39);
      position: absolute;
      bottom: -5px;
    }
  }

  p {
    color: #003680;
    font-size: 1.1rem;
    text-shadow: 0px 0px 1px #6c6c6c;
  }

  .icon {
    color: #003680;
    font-size: 1.2rem;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(238, 238, 253, 0.57);
  }
}
