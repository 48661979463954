.container {
  flex-grow: 10;
  padding: 0 40px 0 10px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  row-gap: 20px;
  width: 60%;
}
@media (max-width: 700px) {
  .container {
    width: 100%;
    padding: 0 10px 10px 10px;
  }
}
.container h2 {
  width: 100%;
  font-size: 2rem;
  text-transform: uppercase;
  color: #457c4d;
  margin-right: 10px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
.container p {
  width: 100%;
  color: #0b2800;
  font-size: 1.1rem;
  text-align: justify;
  line-height: 1.4;
  font-family: "Nunito Sans", sans-serif;
}
.container .blockForBtns {
  display: flex;
  justify-content: space-around;
}
.container .blockForBtns a {
  width: 30%;
  display: flex;
  text-decoration: none;
}
.container .blockForBtns button {
  color: #4cb05b;
  border: 2px solid #52a458;
  width: 100%;
  font-weight: 400;
  transition: 450ms ease;
  font-family: "Nunito Sans", sans-serif;
}
.container .blockForBtns button:hover {
  cursor: pointer;
  color: white;
  background-color: #52a458;
}

