.container {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  padding-left: var(--leftAndRightPadding);
  @media (max-width: 1400px) {
    width: 70%;
  }

  .content {
    width: 100%;
    max-width: 1440px;
  }

  img {
    transition: 300ms;
    height: 50px;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  h1 {
    transition: 700ms;
    color: #003680;
    width: 100%;
    font-size: 0rem;
    color: transparent;
    text-transform: uppercase;
    font-family: robotoBold, cursive;
    text-align: start;
    display: flex;
    align-items: center;
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}
