.container {
  display: flex;
  justify-content: center;

  .wrapper {
    width: 90%;
    max-width: 1300px;
  }

  padding-top: 100px;
}
