.container {
  // background-color: #f5f5f5;
  padding-top: 85px;
  display: flex;
  justify-content: center;
  font-family: Nunito, sans-serif;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    max-width: 1300px;
    padding-bottom: 70px;

    h2 {
      width: 100%;
      padding-bottom: 10px;
      position: relative;
      color: #1c6700;
      font-size: 2rem;
      font-family: Nunito, sans-serif;
      text-transform: uppercase;

      &:after {
        content: "";
        height: 1px;
        width: 70%;
        background: #1c6700;
        left: 15%;
        position: absolute;
        bottom: 0px;

      }

      margin-bottom: 20px;
    }

    p {
      width: 100%;
      font-size: 1.3rem;
    }
  }
}
