.mainContainer {
  height: clamp(250px, 125.7142857143px + 0.3452380952 * 100vw, 540px);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;
  padding-bottom: 10px;
  justify-content: flex-start;
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.mainContainer .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
}
@media (max-width: 700px) {
  .mainContainer .container {
    padding-top: 30px;
    width: 97%;
    row-gap: 10px;
  }
}
.mainContainer .container h1 {
  color: #ffffff;
  font-size: clamp(25px, 20.7142857143px + 0.0119047619 * 100vw, 35px);
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  padding: 8px 29px;
  border-radius: 15px;
  font-weight: 400;
  max-width: 700px;
}
.mainContainer .container .blockForBtns {
  padding: 20px 33px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  column-gap: 20px;
  font-family: robotoBold, cursive;
  margin-top: 28px;
}
@media (max-width: 700px) {
  .mainContainer .container .blockForBtns {
    row-gap: 10px;
  }
}
.mainContainer .container .blockForBtns .btnKnowMore {
  border: 1.6px inset rgb(3, 201, 29);
  box-shadow: 1px 1px 20px 1px inset rgba(204, 204, 204, 0.17);
  text-shadow: 0px 1px 1px black;
  width: 120px;
  font-weight: bold;
  height: 70px;
  aspect-ratio: 1/1;
  border-radius: 25px;
  background-color: rgba(141, 141, 141, 0.17);
  font-size: 0.7rem;
  padding: 0 !important;
  font-family: "Nunito Sans", sans-serif;
}
.mainContainer .container .blockForBtns .btnKnowMore:hover {
  background-color: rgba(124, 124, 124, 0.27);
  cursor: pointer;
}
.mainContainer .container .blockForBtns .btnMakeADonation {
  border: 1.6px inset rgb(217, 211, 0);
  text-shadow: 0px 1px 1px black;
  box-shadow: 1px 1px 20px 1px inset rgba(204, 204, 204, 0.17);
  background-color: rgba(141, 141, 141, 0.17);
  font-weight: bold;
  width: 120px;
  height: 70px;
  aspect-ratio: 1/1;
  border-radius: 25px;
  font-size: 0.7rem;
  padding: 0 !important;
  font-family: "Nunito Sans", sans-serif;
}
.mainContainer .container .blockForBtns .btnMakeADonation:hover {
  background-color: rgba(124, 124, 124, 0.27);
  cursor: pointer;
}

@media (max-width: 700px) {
  .btn {
    font-size: 0.9rem !important;
  }
}

