.backgroundWithTitle {
  padding: 45px 0;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 700px) {
  .backgroundWithTitle {
    margin-bottom: 20px;
  }
}
.backgroundWithTitle h2 {
  text-align: center;
  color: white;
  text-shadow: 0px 1px 1px black;
  font-size: 2rem;
  padding-top: 50px;
  text-transform: uppercase;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 700px) {
  .container {
    flex-direction: column;
  }
}
.container .containerForContentAndBtn {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (max-width: 700px) {
  .container .containerForContentAndBtn {
    width: 90%;
  }
}
.container .containerForContentAndBtn p {
  align-items: start;
}
.container .containerForContentAndBtn p {
  color: black;
  font-size: 1.2rem;
  line-height: 1.4;
}
@media (max-width: 700px) {
  .container .containerForContentAndBtn p {
    padding-bottom: 10px;
  }
}
.container .containerForContentAndBtn .blockForBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container img {
  height: 400px;
}
@media (max-width: 700px) {
  .container img {
    height: auto;
    width: 100%;
    padding-top: 20px;
  }
}

