@import "../../../../mixin";

.mainContainer {
  $divWithImageHight: 700px;
  // min-height: $divWithImageHight;
  height: responsive(250, 540);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;


  padding-bottom: 10px;
  justify-content: flex-start;
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (max-width: 900px) {
    // padding-top: 120px
  }
  @media (max-width: 700px) {
    //   padding-top: 70px
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    @media (max-width: 700px) {
      padding-top: 30px;
      width: 97%;
      row-gap: 10px;
    }

    h1 {
      color: #ffffff;
      font-size: responsive(25, 35);
      text-align: center;
      font-family: "Nunito Sans", sans-serif;
      padding: 8px 29px;
      border-radius: 15px;
      font-weight: 400;
      max-width: 700px;

    }

    //h2 {
    //  font-size: 1.9rem;
    //  color: #72c1e5;
    //  font-family: robotoBold, cursive;
    //  //  text-shadow: 0px 0px 2px black;
    //  text-shadow: 1px 1px 2px black, -1px -1px 2px black;
    //  @media (max-width: 700px) {
    //    font-size: 1.3rem;
    //  }
    //}

    .blockForBtns {
      padding: 20px 33px;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      column-gap: 20px;
      font-family: robotoBold, cursive;
      margin-top: 28px;
      @media (max-width: 700px) {
        //  flex-direction: column;
        row-gap: 10px;
      }
      $size: 120px;

      .btnKnowMore {
        border: 1.6px inset rgb(3, 201, 29);
        box-shadow: 1px 1px 20px 1px inset rgba(204, 204, 204, 0.17);
        text-shadow: 0px 1px 1px black;
        width: $size;
        font-weight: bold;
        height: 70px;
        aspect-ratio: 1/1;
        border-radius: 25px;
        background-color: rgb(141 141 141 / 17%);
        font-size: 0.7rem;
        padding: 0 !important;
        font-family: "Nunito Sans", sans-serif;

        &:hover {
          background-color: rgba(124, 124, 124, 0.27);
          cursor: pointer;
        }
      }

      .btnMakeADonation {
        border: 1.6px inset rgb(217, 211, 0);
        text-shadow: 0px 1px 1px black;
        box-shadow: 1px 1px 20px 1px inset rgba(204, 204, 204, 0.17);
        background-color: rgb(141 141 141 / 17%);
        font-weight: bold;
        width: $size;
        height: 70px;
        aspect-ratio: 1/1;
        border-radius: 25px;
        font-size: 0.7rem;
        padding: 0 !important;
        font-family: "Nunito Sans", sans-serif;

        &:hover {
          background-color: rgba(124, 124, 124, 0.27);
          cursor: pointer;
        }
      }
    }
  }
}

.btn {
  @media (max-width: 700px) {
    font-size: 0.9rem !important;
  }
}
