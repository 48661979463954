.container {
  background-color: #e82323;
}

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0ms;
  font-family: Nunito, cursive;
}
@media (max-width: 1400px) {
  .link:last-child {
    padding-bottom: 10px;
  }
}
.link:hover {
  cursor: pointer;
  color: #061b00;
}
.link a {
  font-family: Nunito, cursive;
  padding: 2px;
}
.link a:hover {
  cursor: pointer;
  color: #081c00;
}
@media (max-width: 1400px) {
  .link {
    flex-direction: row;
  }
}
.link .subMenu {
  transition: 0ms;
  display: flex;
  visibility: hidden;
  min-height: 20px;
  min-width: 200px;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  z-index: 99;
  top: 0;
}
@media (max-width: 1400px) {
  .link .subMenu {
    visibility: visible;
    position: relative;
    min-height: 0;
  }
}
.link .subMenu div {
  transition: 0ms;
  background: #ffffff;
  position: relative;
  top: 40px;
  z-index: 100;
}
@media (max-width: 1400px) {
  .link .subMenu div {
    top: 0px;
    height: 0;
    overflow-y: hidden;
  }
}
.link .subMenu div a, .link .subMenu div span {
  transition: 0ms;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
}
.link:hover .subMenu, .link:focus-within .subMenu {
  visibility: visible;
}
.link:hover .subMenu .arrow, .link:focus-within .subMenu .arrow {
  display: inline;
}
@media (max-width: 1400px) {
  .link:hover .subMenu, .link:focus-within .subMenu {
    position: static;
  }
  .link:hover .subMenu div, .link:focus-within .subMenu div {
    height: auto;
  }
}

.arrow {
  display: none;
  margin-left: 10px;
  transform: rotate(90deg);
}

