.container {
  padding: 20px var(--leftAndRightPadding) 20px var(--leftAndRightPadding);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 25px;

  @media(max-width: 1000px) {
    padding: 25px var(--leftAndRightPadding);
    row-gap: 20px;
  }

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
  }

  .containerForCards {
    width: 85%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    @media(max-width: 1000px) {
      row-gap: 15px;
      width: 100%;
    }
  }

  .blockForBtn {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.mainBtn {
  width: 45%;
  background-color: #E5C201;
  padding: 8px;
}

.arrowBack {
  @media (max-width: 700px) {
    top: 100px !important;;
  }
}

.AllProjects {
  padding: 100px var(--leftAndRightPadding) 20px var(--leftAndRightPadding) !important;
  @media(max-width: 700px) {
    padding-top: 50px !important;
  }
}
