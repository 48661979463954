.container {
  flex-grow: 10;
  padding: 0 40px 0 10px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  row-gap: 20px;
  align-items: center;
  width: 70%;
}
@media (max-width: 1100px) {
  .container {
    width: 90%;
  }
}
@media (max-width: 700px) {
  .container {
    padding: 0 10px 0 10px;
    width: 98%;
  }
}
.container h2 {
  width: 100%;
  text-align: start;
  color: #0e8819;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.container p {
  width: 100%;
  color: #002c03;
  font-size: 1.2rem;
  text-align: justify;
  line-height: 1.4;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.container .image {
  width: 65vw;
  height: min-content;
  border-top: 1.5px solid blue;
  border-left: 1.5px solid blue;
  padding: 10px 0 0px 0;
  position: relative;
  max-width: 100%;
}
@media (max-width: 700px) {
  .container .image {
    display: none;
  }
}
.container .image img {
  position: relative;
  left: 15px;
  top: 5px;
  width: 100%;
}

