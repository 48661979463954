.container {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.container .wrapper {
  width: 90%;
  max-width: 1300px;
}

